import { useState, useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { validateService } from "../service/ValidationService";
import { apiService } from "../service/ApiService";
import swal from "sweetalert";

const Login = () => {
  const navigate = useNavigate();

  const [showPass, setshowPass] = useState(false)
  const [loginFields, setloginFields] = useState({
    email: "",
    password: "",
    role: ""
  })
  const [errors, setErrors] = useState({});


  const onInputChange = (field, value) => {
    setloginFields({
      ...loginFields,
      [field]: value,
    });
  

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    localStorage.clear();
    console.log("token", localStorage.getItem("houdini-auth-token"));
    const formErrors = validateService.login(loginFields)
    if (Object.keys(formErrors).length === 0) {
      const res = await apiService.login(loginFields);
      if (res.status == 200) {
        console.log("response", res);

        localStorage.setItem("houdini-auth-token", res.data.data.token)
        localStorage.setItem("user", JSON.stringify(res.data.data.user))
        swal("Success", "Login successful!", "success")
          .then(() => {
            navigate('/create-referral');
          });
      }
    } else {
      setErrors(formErrors);
    }
  }



  return (
    <>
      {/* LOGIN FORM SECTION */}
      <section className="login-section common-padding">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="login-left-image">
                <img
                  src={require("../assets/images/left-login-img.png")}
                  alt=""
                />
                <div className="login-under-text">
                  <h1>Welcome to
                    Houdini Plumbing!!!</h1>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="login-form-box">
                <img
                  src={require("../assets/images/logo (2).png")}
                  alt=""
                />
                <h2 className="subheading">Login</h2>
                <Form>
                  <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                    <Form.Control type="email" placeholder="Email"
                      value={loginFields.email}
                      onChange={(e) => onInputChange("email", e.target.value)}
                    />
                    {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
                    <img
                      className="login-icon"
                      src={require("../assets/images/email.svg").default}
                      alt=""
                    />
                  </Form.Group>

                  <Form.Group className="mb-5 position-relative" controlId="exampleForm.ControlInput1">
                    <Form.Control type={showPass ? "text" : "password"} placeholder="Password"
                      value={loginFields.password}
                      onChange={(e) => onInputChange("password", e.target.value)}
                    />
                    {errors.password && <Form.Text className="text-danger">{errors.password}</Form.Text>}

                    {showPass ?
                      <img
                        className="login-icon"
                        src={require("../assets/images/eye-slash.svg").default}
                        onClick={() => setshowPass(false)}
                        alt="img"
                      /> :
                      <img
                        className="login-icon"
                        src={require("../assets/images/eye.svg").default}
                        onClick={() => setshowPass(true)}
                        alt="img"
                      />
                    }

                  </Form.Group>
                  <Button type="button" onClick={handleSubmit} className="login-btn">Login</Button>
                  <p className="have-account-text">Don’t have an account? <Link to="/register"> Register</Link></p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Login;
