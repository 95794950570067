import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function StripeReturn() {
  const { connectedAccountId } = useParams();
  const navigate = useNavigate(); 

  const handleClick = () => {
    navigate('/submission'); 
  };

  return (
    <div className="stripe-outer-area">
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <div className="stripe-outer-inner">
              <div className="banner">
                <img src={require("../assets/images/tick.svg").default} alt="img"/>
                {/* <h2>ABC</h2> */}
              </div>
              <div className="content">
                <h2>Details submitted </h2>
                <p>Make sure you have submitted details over stripe.</p>
              </div>
              <div className="info-callout">
                <p>
                  We use stripe to make payouts to our referral rewards. Make sure you have filled all the details.
                  <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=hosted" target="_blank" rel="noopener noreferrer">View docs</a>
                </p>
                <div className="text-center mt-4">
                < button   className="btn btn-primary login-btn w-25" onClick={handleClick}>Ok</button>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </div>

  );
}