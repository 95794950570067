
import client from './AxiosClient'

export const apiService = {
    register,
    login,
    whoAmI,
    createReferral,
    getPartialReferral,
    uploadFile,
    getReferrals,
    getReferralById,
    getReferralFiles,
    getReferralHistory,
    downloadInvoice
}

async function register(data) {
    return await client.post(`/auth/register`, data);
  }

async function login(data) {
    return await client.post(`/auth/login`, data);
  }

async function whoAmI() {
  return await client.get(`/users/whoami`);
}

async function createReferral(ReferralData) {
    return await client.post(`/referral`, ReferralData);
  }

async function uploadFile(fileFormData) {
    return await client.post(`/media`, fileFormData);
}

async function getReferrals(pageNumber,size,search) {
  if (search && search.trim() != "") {
    return await client.get(`/referral?page=`+pageNumber+`&size=`+size+`&propertyName=`+search);
  } else {
    return await client.get(`/referral?page=`+pageNumber+`&size=`+size);
  }  
}

async function getPartialReferral() {
  return await client.get(`/referral/partial`);
}

async function getReferralById(id) {
  return await client.get(`/referral/${id}`);
}

async function getReferralFiles(id) {
  return await client.get(`/media/${id}`);
}

async function getReferralHistory(id) {
  return await client.get(`/referral/history/${id}`);
}

async function downloadInvoice(referralId) {
  return await client.get(`/referral/invoice/${referralId}`, { responseType: 'blob'});
}



