import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import { apiService } from "../service/ApiService";
import swal from "sweetalert";
import * as Yup from 'yup';
import { Form as FForm, Field, Formik, ErrorMessage } from "formik";



const Register = () => {
  const navigate = useNavigate();
  const initialValues = {
    role: 'INDIVIDUAL', name: '', businessName: '', businessContactPerson: '', email: '', contactNumber: '',
    password: '', confirmPassword: ''
  };

  const [showPass, setShowPass] = useState(false)
  const [showPassConfirm, setShowPassConfirm] = useState(false)
  const validationSchema = Yup.object().shape({
    role: Yup.string(),
    name: Yup.string().when('role', {
      is: 'INDIVIDUAL',
      then: (schema) => schema.required('Name is required')
      .matches(/^[a-zA-Z\s]*$/, 'Name must contain only letters.')
      .max(50, 'Name must be at most 80 characters long')    }),
    businessName: Yup.string().when('role', {
      is: 'BUSINESS',
      then: (schema) => schema.required('Business Name is required'),
    }),
    businessContactPerson: Yup.string().when('role', {
      is: 'BUSINESS',
      then: (schema) => schema.required('Contact Person is required'),
    }),
    email: Yup.string().email('Invalid email address')
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'Email address is not valid'
      ).required('Email is required'),
    contactNumber: Yup.string()
      .matches(/^\d{10}$/, 'Contact Number must be exactly 10 digits')
      .required('Contact Number is required'),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
        'Password must be a minimum of 8 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character.'
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const handleKeyDown = (event) => {
    const value = event.target.value;
    if (value.length === 0 && event.key === ' ') {
      event.preventDefault();
    }
  };

  const onSubmit = async (data) => {

    if (data.role === 'INDIVIDUAL') {
      delete data.businessName;
      delete data.businessContactPerson;
    } else if (data.role === 'BUSINESS') {
      delete data.name;
    }

    console.log("data", data)
    const res = await apiService.register(data);
    if (res.status === 201) {
      swal('Success', 'Signup successful!', 'success').then(() => {
        navigate('/');
      });
    }
  };

  const [ type , setType] = useState("INDIVIDUAL")
  const formText = type === 'INDIVIDUAL' ? 'As Individual' : 'As Business';


  const onchangeType =(t) => {
    setType(t)
    console.log(type,t)
  }

  return (
    <>
      {/* REGISTER FORM SECTION */}
      <section className="register-section common-padding">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              {type == 'INDIVIDUAL' && (
                  <div className="login-left-image">                   
                      <img
                      src={require("../assets/images/left-register.png")}
                      alt=""
                    />                        
                    <div className="login-under-text register-under-text">
                      <h1 className="main-text">We’re</h1>
                      <h2 className="sub-main-text">
                        Offering you a <span>Fantastic Opportunity</span> to Earn Money
                      </h2>
                    </div>
                 </div>
                )}
                {type == 'BUSINESS' && (
                 <div className="login-left-image">                   
                    <img
                      src={require("../assets/images/business.png")}
                      alt=""
                    />             
                    <div className="login-under-text register-under-text">
                      <h1 className="main-text">Helping Your</h1>
                      <h1 className="main-text">
                         <span>Friends and</span> Family
                      </h1>
                    </div>
                 </div>
                 )}            
            </Col>
            <Col md={6}>
              <div className="login-form-box">
                <img
                  src={require("../assets/images/logo (2).png")}
                  alt=""
                />
                <h2 className="subheading">
                  Register - <i>{formText}</i>
                </h2>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ setFieldValue, values }) => (
                    <FForm>
                      <div className="check-box-outer">
                        <Form.Check
                          type="radio"
                          label="Register as Individual"
                          checked={values.role == 'INDIVIDUAL'}
                          name="role"
                          id="role"
                          onClick={() => {setFieldValue('role', 'INDIVIDUAL');onchangeType('INDIVIDUAL') } }
                          />
                        <Form.Check
                          type="radio"
                          label="Register as Business"
                          checked={values.role == 'BUSINESS'}
                          name="role"
                          id="role"
                          onClick={() => { setFieldValue('role', 'BUSINESS');onchangeType('BUSINESS') }}
                        />
                      </div>

                      {values.role === 'INDIVIDUAL' && (
                        <div className="login-fields-outer">

                          <div className="login-fields-inner">
                            <Form.Group>
                              <Field
                                type="text"
                                placeholder="Name"
                                id="name"
                                name="name"
                                className="form-control"
                                onKeyDown={handleKeyDown}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      )}

                      {values.role === 'BUSINESS' && (
                        <>
                          <div className="login-fields-outer">
                            <div className="login-fields-inner">
                              <Form.Group className="mb-3 position-relative">
                                <Field
                                  name="businessName"
                                  id="businessName"
                                  placeholder="Business Name"
                                  type="text"
                                  className="form-control"
                                  onKeyDown={handleKeyDown}

                                />
                                <ErrorMessage
                                  name="businessName"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </div>
                          </div>

                          <div className="login-fields-outer">
                            <div className="login-fields-inner">
                              <Form.Group className="mb-3 position-relative">
                                <Field
                                  name="businessContactPerson"
                                  id="businessContactPerson"
                                  placeholder="Contact Person"
                                  type="text"
                                  className="form-control"
                                  onKeyDown={handleKeyDown}

                                />
                                <ErrorMessage
                                  name="businessContactPerson"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="login-fields-outer">
                        <div className="login-fields-inner register-fields-area">
                          <Form.Group>
                            <Field
                              type="email"
                              placeholder="Email"
                              id="email"
                              name="email"
                              className="form-control"
                              onKeyDown={handleKeyDown}

                            />
                            <img
                              src={require("../assets/images/email.svg").default}
                            />
                          </Form.Group>

                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="login-fields-outer">
                        <div className="login-fields-inner register-fields-area">
                          <Form.Group>
                            <Field
                              type="text"
                              placeholder="Contact Number"
                              id="contactNumber"
                              name="contactNumber"
                              className="form-control"
                              onKeyDown={handleKeyDown}
                              onInput={(e) => {
                                const value = e.target.value;
                                const cleanedValue = value.replace(/[^\d]/g, '');
                                if (cleanedValue.length > 10) {
                                  e.target.value = cleanedValue.slice(0, 10);
                                } else {
                                  e.target.value = cleanedValue;
                                }
                              }}
                            />
                          </Form.Group>
                          <img
                            src={require("../assets/images/call.svg").default}
                            alt="Contact Icon"
                          />
                        </div>
                        <ErrorMessage
                          name="contactNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="login-fields-outer">
                        <div className="login-fields-inner register-fields-area">
                          <Form.Group>
                            <Field
                              type={showPass ? 'text' : 'password'}
                              placeholder="Password"
                              id="password"
                              name="password"
                              className="form-control"
                              onKeyDown={handleKeyDown}

                            />
                          </Form.Group>
                          {showPass ? (
                            <img
                              src={require("../assets/images/eye-slash.svg").default}
                              onClick={() => setShowPass(false)}
                              alt="Hide Password"
                            />
                          ) : (
                            <img
                              src={require("../assets/images/eye.svg").default}
                              onClick={() => setShowPass(true)}
                              alt="Show Password"
                            />
                          )}
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="login-fields-outer">
                        <div className="login-fields-inner register-fields-area">
                          <Form.Group>
                            <Field
                              type={showPassConfirm ? 'text' : 'password'}
                              placeholder="Confirm Password"
                              id="confirmPassword"
                              name="confirmPassword"
                              className="form-control"
                              onKeyDown={handleKeyDown}

                            />
                          </Form.Group>
                          {showPassConfirm ? (
                            <img
                              src={require("../assets/images/eye-slash.svg").default}
                              onClick={() => setShowPassConfirm(false)}
                              alt="Hide Password"
                            />
                          ) : (
                            <img
                              src={require("../assets/images/eye.svg").default}
                              onClick={() => setShowPassConfirm(true)}
                              alt="Show Password"
                            />
                          )}
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <Button type="submit" className="login-btn">
                        Register
                      </Button>

                      <p className="have-account-text">
                        Already have an account? <Link to="/"> Login</Link>
                      </p>
                    </FForm>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Register;
