import moment from 'moment';
import { useState, useEffect,useCallback } from "react";
import ReactPaginate from 'react-paginate';
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import stripeService from "../service/StripeService"
import { apiService } from '../service/ApiService';
import { resourceUrl } from "../config/Config";
import { Link } from "react-router-dom";
import { throttle } from 'lodash';
import Header from '../common/Header';
import Loader from '../common/Loader';


const Submission = () => {
    const [show, setShow] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const role = user.role
    const stripeStatus = user.stripeStatus
    const formText = role === 'INDIVIDUAL' ? 'Individual User Form' : 'Business User Form';
    const handleClose = () => setShow(false);
    const [search, setSearch] = useState();
    const [pdfLoading, setPdfLoading] = useState(false);
    const [loader, setLoader] = useState(false);

    // referral 
    const [referrals, setReferrals] = useState([]);
    const [referralCount, setReferralCount] = useState(0);
    const [referralDetail, setReferralDetail] = useState({});
    const [referralFiles, setReferralFiles] = useState({});
    const [charges, setCharges] = useState({});
    const [referralHistory, setReferralHistory] = useState(new Map());

    const createConnectAccount = async () => {
        setLoader(true);
        const response = await stripeService.createConnectAccountLink({});
        if (response.status == 200) {
            setTimeout(() => {
                window.location.replace(response.data.data.url);
            }, 2000);
            
        } else {
            setLoader(false);
        }
    }

    const statusMap = new Map();
    const classMap = new Map();

    statusMap.set('INITIATED', 'Pending');
    classMap.set('INITIATED', 'status-pending');

    statusMap.set('APPROVED', 'Approved');
    classMap.set('APPROVED', 'status-waiting');

    statusMap.set('REJECTED', 'Rejected');
    classMap.set('REJECTED', 'status-rejected');

    statusMap.set('INSTALLED', 'Installed');
    classMap.set('INSTALLED', 'status-installed');

    statusMap.set('COMPLETED', 'Completed');
    classMap.set('COMPLETED', 'status-completed');


    const fetchReferrals = async (pageNumber,search) => {
            const response = await apiService.getReferrals(pageNumber, 10,search)
            if(response.status == 200){
                setReferrals(response.data.data.list);
                setReferralCount(response.data.data.count)
                console.log(response)
            }     
    };

    const handleSearchChange = (e) => {
        const newSearch = e.target.value;
        setSearch(newSearch);
        throttledSearch(newSearch);
    };
    
    const throttledSearch = useCallback(throttle((search) => {
        console.log("search", search);
        if (search && search.trim() !== "") {
            fetchReferrals(0, search);
        } else {
            fetchReferrals(0,search)
        }
    }, 2000), []);

    
    async function getUserObject() {
        if(stripeStatus !== "verified") {
            const response = await apiService.whoAmI();
            console.log("user",response.data.data.user)
            localStorage.setItem("user", JSON.stringify(response.data.data.user))
        }
    };

    const downloadInvoice = async (id) => {
        setPdfLoading(true); 
        const response = await apiService.downloadInvoice(id);
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'houdini-referral-invoice.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          setPdfLoading(false);
        }
    }
    
    // for referral detail  
    const handleShow = async (id) => {
        const response = await apiService.getReferralById(id);
        const imageUrl = await apiService.getReferralFiles(id);
        setReferralDetail(response.data);
        setReferralFiles(imageUrl.data.data.list);
        setCharges(response.data.data.charges);
        fetchReferralHistory(id);
        setShow(true);
    };

    const fetchReferralHistory = async (id) => {
        const response = await apiService.getReferralHistory(id);
        if (response.status == 200) {
            const map = new Map();
            response.data.data.list.forEach(referral => {
                map.set(referral.status, referral)
            })
            setReferralHistory(map)
        }
    }
    const statusMapping = {
        "INITIATED" : {
            text: 'Pending Approval',
            className:'pending',
            imageSrc: require("../assets/images/pending.svg").default,
        },
        "APPROVED" : {
            text: 'Approved',
            className:'approved',
            imageSrc: require("../assets/images/approved-tick.svg").default,
        },
        "REJECTED" : {
            text: 'Rejected',
            className:'rejected',
            imageSrc: require("../assets/images/rejected.svg").default,
        },
        "INSTALLED" : {
            text: 'Installed',
            className:'approved',
            imageSrc: require("../assets/images/approved-tick.svg").default,
        },
        "COMPLETED" : {
            text: 'COMPLETED',
            className:'approved',
            imageSrc: require("../assets/images/approved-tick.svg").default,
        },
    }

    const currentStatus = statusMapping[referralDetail?.data?.status] || {};

    function numberDisplay(value) {
        if (typeof value === 'number' && isFinite(value)) {
            const formattedNumber = value.toFixed(2);
            return parseFloat(formattedNumber).toString();
          } else {
            return 'N/A';
          }
    }

    const handlePageClick = (event) => {

        const selectedPage = event.selected ; // react-paginate uses zero-based index
        fetchReferrals(selectedPage,search);
    };

    useEffect(() => {
        getUserObject();
        fetchReferrals(0);
    }, []);

    return (
        <>      
            <Header />
            <section className="register-section common-padding">

                <Container>                   
                    <div className="individual-form-area">
                        <p>
                            <Link to="/create-referral"><img src={require("../assets/images/back-arrow.svg").default} /> </Link>
                            Your Submissions</p>
                    </div>
                    {stripeStatus !== "verified" && (
                        <div className="submission-inner-content">
                            <div className="error-top">
                                <img src={require("../assets/images/danger.svg").default} alt="img" />
                            </div>
                            <div className="error-msg">
                            <p>You have to connect your stripe account. Please register your account for payment method.</p>
                                {loader ? (
                                <Loader />
                                ) : (
                                <Button type="button" variant="unset" onClick={createConnectAccount}>
                                    Connect Now
                                </Button>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="subsmission-table-area">
                            
                                <div className="submission-search-area">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Property Name..."
                                        value={search}
                                        onChange={handleSearchChange}
                                        className=" mr-sm-2"
                                    />
                                    <img src={require("../assets/images/search-icon.svg").default} />
                                </div>
                                
                                <div className="subsmission-table-area-inner">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Property Name</th>
                                                <th>Property Type</th>
                                                <th>Property Address</th>
                                                <th>Contact Person Name</th>
                                                <th>Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                        { referrals && referrals.length > 0 ? (
                                            
                                                referrals.map(referral => (
                                                    <tr key={referral._id} onClick={() => handleShow(referral._id)}>
                                                        <td>{moment(referral.createdAt).format('DD-MM-YYYY')}</td>
                                                        <td>
                                                        <p className='address-box'>
                                                            {referral.propertyName}
                                                            </p>
                                                            </td>
                                                        <td>{referral.propertyType}</td>
                                                        <td>
                                                            <p className='address-box'>
                                                            {referral.propertyAddress}
                                                            </p>
                                                        </td>
                                                        <td>{referral.contactPersonName}</td>
                                                        <td className={classMap.get(referral.status)}>{statusMap.get(referral.status) || 'N/A'}</td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6">
                                                    <div className='no-data-area'>
                                                        <img src={require("../assets/images/no-data.gif")} alt='no-data'/>
                                                        <h6>No Data Found</h6>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    
                                    </Table>
                                    </div>  
                                {referralCount > 10 && (
                                    <div className='pagination-bottom'>
                                            <ReactPaginate
                                                previousLabel={"previous"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                pageCount={(referralCount / 10)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
        
                                            />
                                    </div>
                                )}                      

                             
                    </div>

                </Container>
            </section>

            {/* form-modal */}
            <Modal show={show} onHide={handleClose} size="xl" className="user-form ps-0">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="user-form-inner">
                        <div className="user-form-top">
                            <h6>{formText}</h6>
                            <div className="user-form-top-right">

                                <p><img src={require("../assets/images/status-completed.svg").default} />Status :&nbsp;
                                    <td className={classMap.get(referralDetail?.data?.status)}>{statusMap.get(referralDetail?.data?.status) || 'N/A'}</td>   
                                </p>

                                {referralDetail?.data?.status === 'COMPLETED' && (
                                    <div style={{ display: 'inline-block', position: 'relative' }}>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                downloadInvoice(referralDetail?.data?._id);
                                            }}  
                                            style={{ visibility: pdfLoading ? 'hidden' : 'visible' }}
                                        >
                                            Download Invoice
                                        </a>
                                        {pdfLoading && (
                                            <div className="loader"></div>
                                        )}                              
                                    </div>     
                                )}                                                                           
                             </div>
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className="user-form-box-body">
                                    <h6>Property Name</h6>
                                    <p>{referralDetail?.data?.propertyName || 'N/A'}</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="user-form-box-body">
                                    <h6>Property Address</h6>
                                    <p>{referralDetail?.data?.propertyAddress || 'N/A'}</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="user-form-box-body">
                                    <h6>Contact Person Name</h6>
                                    <p>{referralDetail?.data?.contactPersonName || 'N/A'}</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="user-form-box-body">
                                    <h6>Additional Details <span>(Optional)</span></h6>
                                    <p>{referralDetail?.data?.additionalDetails || 'N/A'}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="user-form-box-body">
                                    <h6>Measurement</h6>
                                    <p>{referralDetail?.data?.measurement || 'N/A'}</p>
                                </div>
                            </Col>
                            {role === 'BUSINESS' && (
                                <Col md={12}>
                                    <div className="user-form-box-body">
                                        <h6>Quotation Amount</h6>
                                        <p>{referralDetail?.data?.quotationAmount || 'N/A'}</p>
                                    </div>
                                </Col>
                            )}
                            <Col md={12}>
                                <div className="user-form-box-body user-form-photo-area">
                                    <div className="unit-photo-area">
                                        <h6>Photo of the Unit</h6>
                                        {referralFiles && referralFiles.length > 0 ? (
                                            referralFiles
                                                .filter(file => file.title === 'unit')
                                                .map((file, index) => (
                                                    <div key={index}>
                                                        <img
                                                            src={`${resourceUrl}${file.path}`}
                                                            alt={`Unit ${index}`}
                                                            style={{ width: '100%', height: 'auto' }}
                                                        />
                                                        <p>jpg.imag</p>
                                                    </div>
                                                ))
                                        ) : (
                                            <p>No image available</p>
                                        )}
                                    </div>
                                    <div className="unit-photo-area" referralFiles>
                                        <h6>Photo of the Switchboard</h6>
                                        {referralFiles && referralFiles.length > 0 ? (
                                            referralFiles
                                                .filter(file => file.title === 'switchBoard')
                                                .map((file, index) => (
                                                    <div key={index}>
                                                        <img
                                                            src={`${resourceUrl}${file.path}`}
                                                            alt={`Unit ${index}`}
                                                            style={{ width: '100%', height: 'auto' }}
                                                        />
                                                        <p>jpg.imag</p>
                                                    </div>
                                                ))
                                        ) : (
                                            <p>No image available</p>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="user-form-box-body">
                                    <h6>Relocation?</h6>
                                    <p>{referralDetail?.data?.relocation ? 'Yes' : 'No'}</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="user-form-box-body">
                                    <h6>Is the ground concreate, leveled and flat?</h6>
                                    <p>{referralDetail?.data?.isGroundFlat ? 'Yes' : 'No'}</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="user-form-box-body">
                                    <h6>Is the old unit accesssible?</h6>
                                    <p>{referralDetail?.data?.isOldUnitAccessible ? 'Yes' : 'No'}</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="user-form-box-body">
                                    <h6>Do we have clear & clean access in the work area?</h6>
                                    <p>{referralDetail?.data?.isWorkAreaClearNAccessible ? 'Yes' : 'No'}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="user-form-box-body">
                                    <h6>Is the cold & hot water ready on the new location & accessible?</h6>
                                    <p>{referralDetail?.data?.isHotNColdWaterAccessible ? 'Yes' : 'No'}</p>
                                </div>
                            </Col>
                            <Col md={12} className="mt-4">
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="d-flex referral-details">
                                        <h2 className="user-info-heading">Status Management</h2>
                                    </div>
                                </div>
                                <div className="status-management-area">
                                    <div className="current-status">
                                        <h6>Current Status: <span className={currentStatus.className}>{currentStatus.text} <img src={currentStatus.imageSrc} alt="img" /></span></h6>
                                    </div>

                                    <div className="referral-history-outer">
                                        <h6>Referral History</h6>
                                        <div className="referral-history-inner">
                                            <div className="referral-history">
                                                <img src={require("../assets/images/approval-green.svg").default} alt="img" />
                                                {referralDetail?.data?.status == 'INITIATED' && <p>Status: <span>Pending Approval</span></p>}

                                                {
                                                    referralHistory.has('APPROVED') && (
                                                        <>
                                                            <p>Status: <span className="approved">Approved</span></p>
                                                            <h6>Name: {referralHistory.get('APPROVED').title}</h6>
                                                            <h6>Date: {moment(referralHistory.get('APPROVED').createdAt).format('DD-MM-YYYY')}</h6>
                                                        </>
                                                    )
                                                }

                                                {
                                                    referralHistory.has('REJECTED') && (
                                                        <>
                                                            <p>Status: <span className="rejected">Rejected</span></p>
                                                            <h6>Name: {referralHistory.get('REJECTED').title}</h6>
                                                            <h6>Date: {moment(referralHistory.get('REJECTED').createdAt).format('DD-MM-YYYY')}</h6>
                                                        </>
                                                    )
                                                }

                                            </div>

                                            <img className="line" src={require("../assets/images/line.png")} />

                                            <div className="referral-history">
                                                <img 
                                                    src={require("../assets/images/install-green.svg").default} 
                                                    alt="img" 
                                                    className={referralHistory.has('INSTALLED') ? '' : 'bw-filter'}
                                                />
                                                {!referralHistory.has('INSTALLED') && <p>Status: <span className="bw-text"> Install</span></p>}
                                                {!referralHistory.has('INSTALLED') && <p>Pending</p>}
                                                {
                                                    referralHistory.has('INSTALLED') && (
                                                        <>
                                                            {referralHistory.has('INSTALLED') && (
                                                                <div>
                                                                    <p>Status: <span className="approved">Installed</span></p>
                                                                    <h6>Name: {referralHistory.get('INSTALLED').title}</h6>
                                                                    <h6>Date: {moment(referralHistory.get('INSTALLED').createdAt).format('DD-MM-YYYY')}</h6>
                                                                    <h6>Expense: ${numberDisplay(charges?.totalCharges)}</h6>
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </div>

                                            <img className="line" src={require("../assets/images/line.png")} />

                                            <div className="referral-history">
                                                <img 
                                                    src={require("../assets/images/payment.svg").default} 
                                                    alt="img" 
                                                    className={referralHistory.has('COMPLETED') ? '' : 'bw-filter'}
                                                />
                                               
                                                {(!referralHistory.has('COMPLETED') || referralDetail?.data?.earnedCommission == 0) && <p>Status: <span className="bw-text"> Payment</span></p>}
                                                {!referralHistory.has('COMPLETED') && <p>Pending</p>}
                                                {
                                                    referralHistory.has('COMPLETED') && <>
                                                        {referralDetail?.data?.earnedCommission != 0 && (
                                                            <p>Status: <span className="approved">Paid</span></p>
                                                        )}
                                                        <h6>Name: {referralHistory.get('COMPLETED').title}</h6>
                                                        <h6>Date: {moment(referralHistory.get('COMPLETED').createdAt).format('DD-MM-YYYY')}</h6>
                                                        <h6>Stripe ID: {referralDetail?.data?.transferId || 'N/A'}</h6>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            {charges && (
                                <Col md={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex referral-details">
                                            <h2 className="user-info-heading">Installation Details</h2>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Installation Type</h6>
                                                <p>{charges.installationType || 'N/A'}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Unit Charges</h6>
                                                <p>${numberDisplay(charges.unitCharges)}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Additional Charges (RCBO)</h6>
                                                <p>${numberDisplay(charges.rcbo)}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Cable over 5 metres <span>($10.00/ meter)</span></h6>
                                                <p>{numberDisplay(charges.overLimitCable) + " meter"}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Cable Charges</h6>
                                                <p>${numberDisplay(charges.cableCharges)}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Relocation Charges</h6>
                                                <p>${numberDisplay(charges.relocationCharges)}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Water Value</h6>
                                                <p>${numberDisplay(charges.waterValveCharges)}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Re-Concreting</h6>
                                                <p>${numberDisplay(charges.reconcretingCharges)}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Total Charges</h6>
                                                <p>${numberDisplay(charges.totalCharges)}</p>
                                            </div>
                                        </Col>
                                        {role === 'BUSINESS' && (
                                            <Col md={6}>
                                                <div className="referral-detail-body">
                                                    <h6>Proposed Amount</h6>
                                                    <p>${numberDisplay(referralDetail?.data?.quotationAmount)}</p>
                                                </div>
                                            </Col>
                                        )}
                                        <Col md={6}>
                                            <div className="referral-detail-body">
                                                <h6>Commission</h6>
                                                <p>${numberDisplay(referralDetail?.data?.earnedCommission)}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};
export default Submission