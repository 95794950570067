import { Navigate } from 'react-router-dom';


function SecuredRoute({ children }) {
  const isAuthenticated = localStorage.getItem("houdini-auth-token") !== null;
  return isAuthenticated ? children : <Navigate to="/" />;
}

function LoginRoute({ children }) {
  const isAuthenticated = localStorage.getItem("houdini-auth-token") !== null;
  return !isAuthenticated ? children : <Navigate to="/create-referral" />;
}

export {SecuredRoute , LoginRoute};
