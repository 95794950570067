// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from '../component/Register';
import Submission from '../component/Submission';
import StripeReturn from '../component/StripeReturn';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from '../component/Login';
import CreateReferral from '../component/CreateReferral';
import {SecuredRoute,LoginRoute} from './SecuredRoute'; 

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <LoginRoute> 
             <Login />,
      </LoginRoute>
    ),
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/create-referral',
    element: (
      <SecuredRoute>
        <CreateReferral />
      </SecuredRoute>
    ),
  },
  {
    path: '/submission',
    element: (
      <SecuredRoute>
        <Submission />
      </SecuredRoute>
    ),
  },
  {
    path: '/stripe/return/:id',
    element: (
      <SecuredRoute>
        <StripeReturn />
      </SecuredRoute>
    ),
  },

]);

function Routing() {
  return <RouterProvider router={router} />;
}

export default Routing;



