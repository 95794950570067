import React from 'react';

const Loader = () => {
    return (
      <div className="loader-class">
        <div className="spinner"></div>
      </div>
    );
  };

export default Loader;