import { Button, Container } from "react-bootstrap";
import swal from "sweetalert";  

const Header = ({showLogout = true}) => {

    const logout = () => {
        swal({
            title: "Log Out",
            text: "Are you sure you want to logout?",
            icon: "warning",
            buttons: ["No, cancel", "Yes, log out"],
            dangerMode: true,
          }).then((willLogout) => {
            if (willLogout) {
              localStorage.clear();
              window.location.href = "/";
            } else {
            }
          });
      };

    return (
        <>
            {/* HEADER SECTION HERE */}
            <header>
                <Container>
                    <div className="header-main">
                    <img
                      className=""
                      src={require("../assets/images/logo (2).png")}
                      alt=""
                    />
                    {showLogout && (
                       <Button className="login-btn logout-btn" onClick={logout} > Logout</Button>
                    )}
                    </div>
                </Container>
            </header>
        </>
    )
};
export default Header;  

