import { createContext } from "react";
import swal from "sweetalert";
import { useNavigate} from "react-router-dom";

console.log(process.env.REACT_APP_API_URL,"REACT_APP_API_URL")
console.log(process.env.REACT_APP_RESOURCE_URL,"REACT_APP_RESOURCE_URL")

export const apiUrl = process.env.REACT_APP_API_URL
export const resourceUrl = process.env.REACT_APP_RESOURCE_URL

export const EMAILREGEX =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PasswordRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const errorHandling=(error)=>{

    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        swal.fire({
          title: 'Error',
          text: 'You are not authorized to access this page. Please login.',
          icon: 'error',
        }).then(() => {
          localStorage.clear(); 
          window.location.href = "/"   
        });
    } else {
        const errorMessage = error.response?.data?.message || 'An unknown error occurred';
        console.log("error",error)
        swal("Error", errorMessage, "error");
    }
}