import { EMAILREGEX,PasswordRegex} from "../config/Config";

export const validateService = {
    login
}

function login(loginFields){
    const errors = {};

    emailAndPasswordCheck(loginFields,errors)
    return errors;
}

function emailAndPasswordCheck(fields,errors){
  if (!fields.email) {
      errors.email = 'Email is required';
    } else if (!EMAILREGEX.test(fields.email)) {
      errors.email = 'Email is invalid';
    }  
  if (!fields.password) {
      errors.password = 'Password is required';
  } 
}

  


