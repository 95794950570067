import { Button, Col, Container, Form, Row,Dropdown } from "react-bootstrap";
import { Link ,useNavigate} from "react-router-dom";
import React, { useEffect, useState } from 'react';
import swal from "sweetalert";  
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { apiService } from "../service/ApiService";
import Header from "../common/Header";

const CreateReferral = () => {

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user.role
  const [unitFileView, setUnitFileView] = useState();
  const [switchBoardFileView, setSwitchBoardFileView] = useState();
  const [files, setFiles] = useState({
    unitPhoto: null,
    switchboardPhoto: null
  });

  // testing purpose
  const [incompleteReferral , setIncompleteReferral] = useState([]);

  useEffect(() => {
    const fetchReferrals = async () => {
      const response = await apiService.getPartialReferral()
      if(response.status == 200){
          setIncompleteReferral(response.data.data.list);
          console.log("response testing" , response.data);
      }     
    };
    fetchReferrals();
  },[]);

  const options = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ];
  const propertyTypeOption = [
    { value: "Commercial", label: "Commercial" },
    { value: "Residential", label: "Residential" }
  ];

  const handleFileChange = (event, name) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > FILE_SIZE) {
        swal("Error", "File size exceeds 10 MB limit.", "error");
        return;
      }   
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        swal("Error", "Unsupported file format. Only jpg, jpeg, and png are allowed.", "error");
        return;
      }
      setFiles(prevFiles => ({
        ...prevFiles,
        [name]: file
      }));

      const reader = new FileReader();
      reader.onload = () => {
        if (name === 'unitPhoto') {
          setUnitFileView(reader.result);
        } else if (name === 'switchboardPhoto') {
          setSwitchBoardFileView(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const FILE_SIZE = 10 * 1024 * 1024; // 10 MB
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  const validationSchema = Yup.object().shape({
    propertyName: Yup.string().required('Property name  is required').trim(),
    propertyAddress: Yup.string().required('Property address is required'),
    contactPersonName: Yup.string().required('Contact person name is required').matches(/^[a-zA-Z\s]*$/, 'Can not contain numbers.'),
    additionalDetails:Yup.string(),
    measurement: Yup.string().required('Measurement is required'),
    propertyType: Yup.string().required('Required'),
    relocation: Yup.boolean().required('Relocatioin is required'),
    isGroundFlat: Yup.boolean().required('Required'),
    isOldUnitAccessible: Yup.boolean().required('Required'),
    isWorkAreaClearNAccessible: Yup.boolean().required('Required'),
    isHotNColdWaterAccessible: Yup.boolean().required('Required'), 
    quotationAmount: role === 'BUSINESS' 
    ? Yup.number().typeError('Quotation must be a number').required('Quotation is required') 
    : Yup.string()
  });

  const handleKeyDown = (event) => {
    const value = event.target.value;
    if (value.length === 0 && event.key === ' ') {
      event.preventDefault(); // Prevent space at the start
    }
  };

   // Initialize the form with useForm hook
   const { control, handleSubmit,setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });



  // function to upload unit/switchBoard images
  function uploadFileData(res,title,file){
    const fileFormData = new FormData();

    fileFormData.append('file', file);
    fileFormData.append("title",title)
    fileFormData.append("referral",res.data.data._id)
    apiService.uploadFile(fileFormData); 
  }

  

  // Handle form submission
  const onSubmit = async (data) => {
      console.log("referral data" ,data)
      if (!files.unitPhoto) {
        swal("Error", "Please upload the photo of the unit.", "error");
        return;
      }
      if (!files.switchboardPhoto) {
        swal("Error", "Please upload the photo of the switchboard.", "error");
        return; 
      }
      const res = await apiService.createReferral(data);
      if(res.status=== 200){
        if (files.unitPhoto){
          uploadFileData(res,"unit",files.unitPhoto)
        }
        if(files.switchboardPhoto){
          uploadFileData(res,"switchBoard",files.switchboardPhoto)
        }
        swal("Success", "Referral Submitted Successfully.", "success")
        .then(() => {
          navigate('/submission');
        });
      }
  }

  const handleIncompleteRefferal = async (id) => {
    const referral = incompleteReferral.find(ref => ref._id === id);
    if (referral) {
        const data = referral;
        const schemaFields = Object.keys(validationSchema.fields);
      
      schemaFields.forEach((field) => {
        if (data.hasOwnProperty(field)) {
          setValue(field, data[field]);
        }
      });
      setValue("partialReferralId",referral._id);
    } 
  }
 

  return (
    <>
      {/* REGISTER FORM SECTION */}
      <Header  />

      <section className="register-section common-padding">
        <Container>
          <div className="individual-form-area">
            {role === 'INDIVIDUAL' && (
              <p><img src={require("../assets/images/user-icon.svg").default} />Individual User Form</p>
            )}
            {role === 'BUSINESS' && (
              <p><img src={require("../assets/images/business-icon.svg").default} />Business User Form</p>
            )}

            <div className="submission-view-btn">
              {role === 'INDIVIDUAL' &&(
                <img src={require("../assets/images/button-bg.png")} />
              )}
               {role === 'BUSINESS' &&(
                <img src={require("../assets/images/button-bg-green.png")} />
              )}

              <h6> <Link to="/submission"> View Submission
                 <img src={require("../assets/images/eye-black.svg").default} /></Link></h6>
            </div>
          </div>   

          <div className="individual-form-inner responsive-individual-form">

          {incompleteReferral.length > 0 && (
            <div className="incomplete-referral-section">
                <div>
                  <p>Some referrals from another portal are incomplete. Please complete them by selecting property name.</p>
                </div>

               <Dropdown>
                 <Dropdown.Toggle variant="success" id="dropdown-basic">
                   Select Property Name
                 </Dropdown.Toggle>
                 <Dropdown.Menu>
                 {incompleteReferral.map(referral => (
                   <Dropdown.Item key={referral.id} onClick={() => handleIncompleteRefferal(referral._id)}>{referral.propertyAddress} </Dropdown.Item>
                 ))}    
                 </Dropdown.Menu>
               </Dropdown>
            </div>
          )}    

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} lg={4}>
                <Form.Group className="mb-3" controlId="propertyName">
                  <Form.Label>Property Name</Form.Label>
                  <Controller
                      name="propertyName"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Enter Property Name"
                          isInvalid={!!errors.propertyName}
                          {...field}
                          onKeyDown={handleKeyDown}
                        />
                      )}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.propertyName?.message}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={4}>
                <Form.Group className="mb-3" controlId="propertyAddress">
                  <Form.Label>Property Address</Form.Label>
                  <Controller
                      name="propertyAddress"
                      control={control} 
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Enter Property Address"
                          isInvalid={!!errors.propertyAddress}
                          {...field}
                          onKeyDown={handleKeyDown} 
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.propertyAddress?.message}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={4}>
                <Form.Group className="mb-3" controlId="contactPersonName">
                  <Form.Label>Contact Person Detail</Form.Label>
                  <Controller
                      name="contactPersonName"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Enter Contact Person Detail"
                          isInvalid={!!errors.contactPersonName}
                          {...field}
                          onKeyDown={handleKeyDown} 
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contactPersonName?.message}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={4}>
                <Form.Group className="mb-3" controlId="additionalDetails">
                  <Form.Label>Additional Details <span>(Optional)</span></Form.Label>
                  <Controller
                      name="additionalDetails"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Enter Additional Details"
                          isInvalid={!!errors.additionalDetails}
                          {...field}
                          onKeyDown={handleKeyDown} 
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.additionalDetails?.message}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={4}>
                <Form.Group className="mb-3" controlId="measurement">
                <Form.Label>Measurement</Form.Label>
                <Controller
                      name="measurement"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Enter Measurement"
                          isInvalid={!!errors.measurement}
                          {...field}
                          onKeyDown={handleKeyDown} 
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.measurement?.message}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {role === 'BUSINESS' && (
              <Col md={6} lg={4}>
                <Form.Group className="mb-3" controlId="quotationAmount">
                <Form.Label>Quotation Amount(eg:$1500)</Form.Label>
                <Controller
                      name="quotationAmount"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Enter Quotation Amount"
                          isInvalid={!!errors.quotationAmount}
                          {...field}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.quotationAmount?.message}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              )}
              
              <Col md={6} lg={4}>
                <Form.Group className="mb-3" controlId="propertyType">
                  <Form.Label>Property Type</Form.Label>
                  <Controller
                    name="propertyType"
                    control={control}
                    defaultValue="Commercial"
                    render={({ field }) => (
                      <Form.Select
                        isInvalid={!!errors.propertyType}
                        {...field}
                      >
                        {propertyTypeOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.propertyType?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={8} lg={8}>
              </Col>

              <Col md={12} lg={12}>
                <div className="unit-switchboard-upload-area">
                  {/* Photo of the Unit */}
                  <div className="unit-photo-upload">
                    <Form.Label>Photo of the Unit</Form.Label>
                    <Controller
                      name="unitPhoto"
                      control={control}
                      render={({ field }) => (
                        <>
                          <div className="upload-doc-area">
                            <Form.Control
                              type="file"
                              onChange={(e) => handleFileChange(e, 'unitPhoto')}
                            />
                             <img
                              src={unitFileView?unitFileView:require("../assets/images/upload-img.svg").default}
                              alt="Upload Icon"
                            />
                          </div>
                          <p>{files.unitPhoto?.name || 'No file selected.'}</p>
                        </>
                      )}
                    />
                  </div>
                  
                  {/* Photo of the Switchboard */}
                  <div className="unit-photo-upload">
                    <Form.Label>Photo of the Switchboard</Form.Label>
                    <Controller
                      name="switchboardPhoto"
                      control={control}
                      render={({ field }) => (
                        <>
                          <div className="upload-doc-area">
                            <img
                              src={switchBoardFileView?switchBoardFileView:require("../assets/images/upload-img.svg").default}
                              alt="Upload Icon"
                            />
                            <Form.Control
                              type="file"
                              onChange={(e) => handleFileChange(e, 'switchboardPhoto')}
                            />
                          </div>
                          <p>{files.switchboardPhoto?.name || 'No file selected.'}</p>
                        </>
                      )}
                    />
                  </div>
                </div>
              </Col>
            
              <Col md={6} lg={4}>
                <div className="user-form-slect-area">
                  <Form.Group
                    className="mb-3"
                    controlId="relocation"
                  >
                    <Form.Label>Relocation? </Form.Label>
                    <Controller
                      name="relocation"
                      control={control}
                      defaultValue="true"
                      render={({ field }) => (
                        <Form.Select options={options} 
                        isInvalid={!!errors.relocation}
                        {...field} styles={{
                          control: (base, state) => ({
                              // ...base,
                              background: "#fff",
                              border: "1px solid #E0E0E0",
                              padding: "6px",
                          }),
                          input: (base, state) => ({
                              ...base,
                              color:"000"
                          })
                        }}>
                           {options.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.relocation?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>

              <Col md={6} lg={4}>
                <div className="user-form-slect-area">
                  <Form.Group
                    className="mb-3"
                    controlId="isGroundFlat"
                  >
                    <Form.Label>Is the ground concreate, leveled and flat?</Form.Label>
                    <Controller
                      name="isGroundFlat"
                      control={control}
                      defaultValue="true"
                      render={({ field }) => (
                        <Form.Select options={options} 
                        isInvalid={!!errors.isGroundFlat}
                        {...field} styles={{
                          control: (base, state) => ({
                              // ...base,
                              background: "#fff",
                              border: "1px solid #E0E0E0",
                              padding: "6px",
                          }),
                          input: (base, state) => ({
                              ...base,
                              color:"000"
                          })
                      }}>
                      {options.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                    <Form.Control.Feedback type="invalid">
                      {errors.isGroundFlat?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="user-form-slect-area">
                  <Form.Group
                    className="mb-3"
                    controlId="isOldUnitAccessible"
                  >
                    <Form.Label>Is the old unit accesssible?</Form.Label>
                    <Controller
                      name="isOldUnitAccessible"
                      control={control}
                      defaultValue="true"
                      render={({ field }) => (
                        <Form.Select options={options} 
                        isInvalid={!!errors.isOldUnitAccessible}
                        {...field} styles={{
                          control: (base, state) => ({
                              // ...base,
                              background: "#fff",
                              border: "1px solid #E0E0E0",
                              padding: "6px",
                          }),
                          input: (base, state) => ({
                              ...base,
                              color:"000"
                          })
                      }}>
                      {options.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                  />
                    <Form.Control.Feedback type="invalid">
                      {errors.isOldUnitAccessible?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="user-form-slect-area">
                  <Form.Group
                    className="mb-3"
                    controlId="isWorkAreaClearNAccessible"
                  >
                    <Form.Label>Do we have clear & clean access in the work area?</Form.Label>
                    <Controller
                      name="isWorkAreaClearNAccessible"
                      control={control}
                      defaultValue="true"
                      render={({ field }) => (
                        <Form.Select options={options} 
                        isInvalid={!!errors.isWorkAreaClearNAccessible}
                        {...field} styles={{
                          control: (base, state) => ({
                              // ...base,
                              background: "#fff",
                              border: "1px solid #E0E0E0",
                              padding: "6px",
                          }),
                          input: (base, state) => ({
                              ...base,
                              color:"000"
                          })
                      }}>
                      {options.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                    <Form.Control.Feedback type="invalid">
                      {errors.isWorkAreaClearNAccessible?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="user-form-slect-area">
                  <Form.Group
                    className="mb-3"
                    controlId="isHotNColdWaterAccessible"
                  >
                    <Form.Label>Is the cold & hot water ready on the new location & accessible?</Form.Label>
                    <Controller
                      name="isHotNColdWaterAccessible"
                      control={control}
                      defaultValue="true"
                      render={({ field }) => (
                        <Form.Select options={options} 
                        isInvalid={!!errors.isHotNColdWaterAccessible}
                        {...field} styles={{
                          control: (base, state) => ({
                              // ...base,
                              background: "#fff",
                              border: "1px solid #E0E0E0",
                              padding: "6px",
                          }),
                          input: (base, state) => ({
                              ...base,
                              color:"000"
                          })
                      }}>
                      {options.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                    <Form.Control.Feedback type="invalid">
                      {errors.isHotNColdWaterAccessible?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Button type="submit" variant="unset">Submit</Button>
            </Form>
          </div>

        </Container>
      </section>
    </>
  );
};
export default CreateReferral;
